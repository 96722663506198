<template>
    <div class="mobile-page-layout">
        <div class="mobile-page-header">
            <div class="mobile-page-header-row">
                <div class="mobile-page-header-right">
                    <router-link :to="{ name: 'HomeIntro'}" class="mobile-page-header-icon pointer back-icon">
                        <i class="header-uil-icon uil uil-angle-right-b"></i>
                    </router-link>
                    <div class="mobile-page-header-icon">
                        <div class="mobile-page-header-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="header-svg-icon">
                                <path class="fa-primary" d="M512 288c0 17.67-14.31 32-32 32h-64v32c0 15.11-3.088 29.4-7.895 42.86l62.52 62.52c12.5 12.5 12.5 32.75 0 45.25C464.4 508.9 456.2 512 448 512s-16.38-3.125-22.62-9.375l-54.13-54.13C348.8 467.9 319.1 480 288 480h-16V239.9C272 231.2 264.8 224 256 224S240.1 231.2 240.1 239.9V480H224c-31.96 0-60.82-12.13-83.25-31.5l-54.13 54.13C80.38 508.9 72.19 512 64 512s-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25l62.52-62.52C99.09 381.4 96 367.1 96 352v-32H32c-17.69 0-32-14.33-32-32s14.31-32 32-32h64V205.3L41.38 150.6c-12.5-12.5-12.5-32.75 0-45.25C47.62 99.13 55.81 96 63.1 96c8.188 0 16.38 3.125 22.63 9.375L141.3 160h229.5l54.63-54.63C431.6 99.13 439.8 96 447.1 96c8.188 0 16.38 3.125 22.63 9.375c12.5 12.5 12.5 32.75 0 45.25L416 205.3V256h64C497.7 256 512 270.3 512 288z"></path>
                                <path class="fa-secondary" d="M352 96c0-53.02-42.98-96-96-96S160 42.98 160 96v64h192V96z"></path>
                            </svg>
                        </div>
                    </div>
                    <div class="mobile-page-header-text">گزارش خطا</div>  

                </div>
            </div>
        </div>

        <iframe id="bx_form_iframe_3" name="bx_form_iframe_3" src="https://erp.tgju.org/pub/form.php?view=frame&amp;form_id=3&amp;widget_user_lang=en&amp;sec=sgfalr&amp;r=1663478229001#%7B%22domain%22%3A%22https%3A%2F%2Fwww.tgju.org%22%2C%22from%22%3A%22https%3A%2F%2Fwww.tgju.org%2Fgo%2Ftools%22%2C%22options%22%3A%7B%7D%7D" scrolling="no" marginheight="0" marginwidth="0" style="width: 100%; height: 700px; border: 0px none; overflow: auto; padding: 0px 15px; margin: 0px;" frameborder="0"></iframe>

    </div>
</template>


<script>
    export default {
        name: 'Report',
        components: {},
        data: function () {
            return {
            }
        },
        mounted() {},
        computed: {},
        methods: {},
    }
</script>